
import { defineComponent, computed, ref, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useAuth0 } from "@auth0/auth0-vue";
import { useStore } from "@/store";
import { Challenge } from "@/store/challenges/state";
import {
  exampleChallenge,
  minChallengeTextLength,
} from "@/store/challenge/state";
import ChallengesList from "../challenges-list/ChallengesList.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import ContentBlock from "@/components/ui/content-block/ContentBlock.vue";
import ContentBlockDivider from "@/components/ui/content-block/ContentBlockDivider.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import AppButtonRouterLink from "@/components/ui/app-button/AppButtonRouterLink.vue";
import Multiselect from "@/components/ui/multiselect/Multiselect.vue";
import AutoResizeTextarea from "@/components/ui/auto-resize-textarea/AutoResizeTextarea.vue";
import GuidedTour from "@/components/ui/guided-tour/GuidedTour.vue";
import ArrowUpIcon from "@/assets/images/icon-arrow-up.svg";
import ArrowRightIcon from "@/assets/images/icon-arrow-right.svg";

export default defineComponent({
  components: {
    ChallengesList,
    ContentBlock,
    ContentBlockDivider,
    AppButton,
    AppButtonRouterLink,
    Editorial,
    Multiselect,
    AutoResizeTextarea,
    GuidedTour,
    ArrowUpIcon,
    ArrowRightIcon,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const auth0 = useAuth0();

    const isLoadingChallenge = computed(() => store.state.challenge.isLoading);

    // form values
    const inputTypes = ref<string[]>([]);
    const inputSubject = ref<string[]>([]);
    const inputTags = ref<string[]>([]);
    const inputDescription = ref("");

    const showTags = ref(false);
    const toggleTags = () => {
      showTags.value = !showTags.value;

      if (showTags.value) {
        store.dispatch("tracking/TRACK_EVENT", {
          action: "CHALLENGE_FORM_SHOW_TAGS",
        });
      } else {
        store.dispatch("tracking/TRACK_EVENT", {
          action: "CHALLENGE_FORM_HIDE_TAGS",
        });
      }
    };

    const hasEnoughText = computed(
      () => inputDescription.value.trim().length >= minChallengeTextLength,
    );

    const submitIsDisabled = computed(() => {
      return !(
        inputSubject.value.length &&
        inputTypes.value.length &&
        hasEnoughText.value
      );
    });

    // if the user is logged in, we prioritise their top subjects first,
    // otherwise we just list all subjects
    const userSubjectOptions = computed(
      () => store.getters["challenges/getSubjects"],
    );
    const allSubjectOptions = computed(
      () => store.state.challenges.subjectTypes,
    );

    const typesOptions = computed(() => store.state.challenges.challengeTypes);
    const tagsOptions = computed(() => store.state.challenges.challengeTags);

    const handleContinue = async () => {
      const challenge: Partial<Challenge> = {
        subject: inputSubject.value.join(", "),
        description: inputDescription.value,
        types: inputTypes.value,
        tags: inputTags.value,
      };
      let id;
      if (auth0.isAuthenticated.value) {
        id = await store.dispatch("challenges/ADD_CHALLENGE", challenge);
      }
      store.commit("challenge/SET_CHALLENGE", challenge);
      store.dispatch("challenge/GET_AI_CHALLENGE_TITLE");
      if (id) {
        router.push({
          name: `challenge-saved`,
          params: { id },
        });
      } else {
        router.push({
          name: `challenge-new-inspiration`,
        });
      }
    };

    const toggleSignUp = async () => {
      auth0.loginWithRedirect({
        authorizationParams: { action: "signup" },
        appState: { target: route.fullPath },
      });
    };

    const toggleLogin = async () => {
      auth0.loginWithRedirect({
        appState: { target: route.fullPath },
      });
    };

    const showTryExampleButton = computed(
      () =>
        inputDescription.value.length === 0 &&
        inputSubject.value.length === 0 &&
        inputTypes.value.length === 0,
    );

    const handleTryExample = () => {
      inputDescription.value = exampleChallenge.description;
      inputSubject.value.push(exampleChallenge.subject);
      inputTypes.value.push(exampleChallenge.type);

      store.dispatch("tracking/TRACK_EVENT", {
        action: "CHALLENGE_FORM_USE_EXAMPLE",
      });
    };

    const disableClearAllButton = computed(
      () =>
        inputDescription.value.length === 0 &&
        inputSubject.value.length === 0 &&
        inputTypes.value.length === 0 &&
        inputTags.value.length === 0,
    );

    const handleClearAll = () => {
      inputDescription.value = "";
      inputSubject.value = [];
      inputTypes.value = [];
      inputTags.value = [];

      store.commit("challenge/CLEAR_CHALLENGE");
    };

    const requestUserFormData = () => {
      store.dispatch("challenges/GET_USER_CHALLENGE_TYPES");
      store.dispatch("challenges/GET_USER_SUBJECT_TYPES");
      store.dispatch("challenges/GET_USER_CHALLENGE_TAGS");
    };

    const requestAllFormData = () => {
      store.dispatch("challenges/GET_ALL_CHALLENGE_TYPES");
      store.dispatch("challenges/GET_ALL_SUBJECT_TYPES");
      store.commit("challenges/CLEAR_CHALLENGE_TAGS");
    };

    onMounted(async () => {
      if (auth0.isAuthenticated.value) {
        requestUserFormData();
      } else {
        requestAllFormData();
      }
    });

    // Make the same API requests for users who have just logged in
    // (as they have logged in after the component has mounted)
    watch(
      () => auth0.isAuthenticated.value,
      async (newVal, oldVal) => {
        if (newVal && !oldVal) {
          requestUserFormData();
        } else {
          requestAllFormData();
        }
      },
    );

    // Update the store on whether the example button is shown - needed
    // for the guided tour.
    watch(
      showTryExampleButton,
      () => {
        store.commit(
          "challenge/SET_EXAMPLE_BUTTON_SHOWN",
          showTryExampleButton.value,
        );
      },
      { immediate: true },
    );

    return {
      isAuthenticated: auth0.isAuthenticated,
      handleTryExample,
      showTryExampleButton,
      toggleSignUp,
      toggleLogin,
      submitIsDisabled,
      isLoadingChallenge,
      disableClearAllButton,
      handleClearAll,
      handleContinue,
      showTags,
      toggleTags,
      inputTypes,
      inputSubject,
      inputDescription,
      inputTags,
      userSubjectOptions,
      allSubjectOptions,
      typesOptions,
      tagsOptions,
    };
  },
});
