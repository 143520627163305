import { Challenge } from "../challenges/state";

export const exampleChallenge = {
  description:
    "The swimmer is asking for strategies to reduce her tiredness. She is not reporting a lack of sleep, her nutrition and diet are balanced, and she undergoes regular recovery processes after exercising to promote healing.",
  subject: "swimmer",
  type: "General performance issue",
};

export const minChallengeTextLength = 100;

export type Idea = {
  _id: string;
  description: string;
  notes: string;
  challenge: string;
  origin: string;
  sparkText: string;
  stem: Record<string, string>;
  isFavorite: boolean;
  createdAt?: string;
  updatedAt?: string;
};

export type Solution = {
  _id: string;
  ideas: string[];
  description: string;
  sharedWith: string;
  successCriteria: string;
  confidenceLevel: number;
  helpsOutcome: string[];
  preventsOutcome: string[];
  targetDate: string;
  challenge: string;
  createdAt?: string;
  updatedAt?: string;
};

export type Resolution = {
  solution: string;
  reflection: string;
  rating: number;
  positives: string;
  negatives: string;
};

export type EntityType = "Thing" | "Place" | "Organisation" | "Person";

export type Entity = {
  label: string;
  type: EntityType;
  count: number;
};

export type Order = {
  key: "updatedAt" | "description";
  value: string;
};

// Declare state
export type State = {
  isLoading: boolean;
  isLoadingChallengeTitle: boolean;
  isDeleting: boolean;
  isShownExampleButton: boolean;
  challenge: Challenge | null;
  isLoadingPlan: boolean;
  entities: Entity[];
};

// Create initial state
export const state = {
  isLoading: false,
  isLoadingChallengeTitle: false,
  isDeleting: false,
  isShownExampleButton: true,
  challenge: null,
  isLoadingPlan: false,
  entities: [],
} as State;
