
import { defineComponent, ref, computed, watch, reactive } from "vue";
import { useRoute, onBeforeRouteLeave } from "vue-router";
import isEqual from "lodash.isequal";
import router from "@/router";
import { useStore } from "@/store";
import { useAuth0 } from "@auth0/auth0-vue";
import { getRelativeDateString } from "@/utils/date";
import { useModal } from "@/composables/useModal";
import { useElementSize } from "@/composables/useElementSize";
import { Challenge } from "@/store/challenges/state";
import { minChallengeTextLength } from "@/store/challenge/state";
import ModalContent from "@/components/ui/modal-content/ModalContent.vue";
import Editorial from "@/components/ui/editorial/Editorial.vue";
import Multiselect from "@/components/ui/multiselect/Multiselect.vue";
import AppButton from "@/components/ui/app-button/AppButton.vue";
import AutoResizeTextarea from "@/components/ui/auto-resize-textarea/AutoResizeTextarea.vue";
import LoadingIcon from "@/components/ui/loading-icon/LoadingIcon.vue";
import PlusIcon from "@/assets/images/icon-plus.svg";
import MinusIcon from "@/assets/images/icon-minus.svg";
import BinIcon from "@/assets/images/icon-bin.svg";

export default defineComponent({
  components: {
    ModalContent,
    Editorial,
    Multiselect,
    AppButton,
    AutoResizeTextarea,
    LoadingIcon,
    PlusIcon,
    MinusIcon,
    BinIcon,
  },
  props: {
    isEditable: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const auth0 = useAuth0();

    // expandable
    const isExpanded = ref(false);
    const expandedContentEl = ref<HTMLElement | null>(null);
    const { elementHeight } = useElementSize(expandedContentEl);
    const handleExpansionToggle = () => {
      isExpanded.value = !isExpanded.value;
    };

    // challenge
    const challenge = computed(() => store.state.challenge.challenge);

    const challengeIsActive = computed(
      () => store.getters["challenge/getIsActive"],
    );

    // challenge title
    const challengeTitleEl = ref<typeof AutoResizeTextarea | null>(null);
    const challengeTitle = ref("");
    const isLoadingChallengeTitle = computed(
      () => store.state.challenge.isLoadingChallengeTitle,
    );

    // The challenge title API request returns asynchronously
    // We should use its value if we haven't already got a challenge title set
    watch(isLoadingChallengeTitle, (newVal, oldVal) => {
      if (!!oldVal && !newVal && challengeTitle.value === "") {
        challengeTitle.value = store.state.challenge.challenge?.title || "";
      }
    });

    // challenge description
    const challengeDescriptionEl = ref<typeof AutoResizeTextarea | null>(null);
    const challengeDescription = ref("");

    // expanded form values
    const challengeTypes = ref<string[]>([]);
    const challengeSubjects = ref<string[]>([]);
    const challengeTags = ref<string[]>([]);

    // if the user is logged in, we prioritise their top subjects first,
    // otherwise we just list all subjects
    const userSubjectOptions = computed(
      () => store.getters["challenges/getSubjects"],
    );
    const allSubjectOptions = computed(
      () => store.state.challenges.subjectTypes,
    );

    const typesOptions = computed(() => store.state.challenges.challengeTypes);
    const tagsOptions = computed(() => store.state.challenges.challengeTags);

    const createdAt = computed(
      () => store.state.challenge.challenge?.createdAt,
    );
    const updatedAt = computed(
      () => store.state.challenge.challenge?.updatedAt,
    );

    // challenge content
    const challengeId = computed(() => route.params.id);
    const isLoading = computed(() => store.state.challenge.isLoading);

    const requestUserFormData = () => {
      store.dispatch("challenges/GET_USER_CHALLENGE_TYPES");
      store.dispatch("challenges/GET_USER_SUBJECT_TYPES");
      store.dispatch("challenges/GET_USER_CHALLENGE_TAGS");
    };

    const requestAllFormData = () => {
      store.dispatch("challenges/GET_ALL_CHALLENGE_TYPES");
      store.dispatch("challenges/GET_ALL_SUBJECT_TYPES");
      store.commit("challenges/CLEAR_CHALLENGE_TAGS");
    };

    // delete content
    const displayDeleteButton = computed(() => !!challengeId.value);

    const modalState = reactive({
      deleteChallenge: false,
    });

    const { isShown, showModal, closeModal } = useModal(modalState);

    const showDeleteChallengeModal = () => showModal("deleteChallenge");

    const isDeletingChallenge = ref(false);

    const handleDeleteChallenge = async () => {
      await store.dispatch("challenge/DELETE_CHALLENGE", challengeId.value);
      closeModal("deleteChallenge");
      store.commit("challenge/CLEAR_CHALLENGE");
      isDeletingChallenge.value = true;
      router.push({ name: "challenges-new" });
    };

    // has anything changed? check if the initial data matches the current input values.
    const hasInitialDetails = computed(
      () =>
        challengeTitle.value === challenge.value?.title &&
        challengeDescription.value === challenge.value?.description &&
        isEqual(challengeTypes.value, challenge.value?.types) &&
        isEqual(
          challengeSubjects.value,
          challenge.value?.subject.split(", "),
        ) &&
        isEqual(challengeTags.value, challenge.value?.tags),
    );

    const hasEnoughText = computed(
      () => challengeDescription.value.trim().length >= minChallengeTextLength,
    );

    const submitIsDisabled = computed(() => {
      return !(
        challengeSubjects.value.length &&
        challengeTypes.value.length &&
        hasEnoughText.value &&
        challengeTitle.value.length
      );
    });

    // update content
    const handleUpdate = async () => {
      if (challengeId.value) {
        // if we're updating a saved challenge, update the API too
        await store.dispatch("challenge/UPDATE_CHALLENGE", {
          _id: challengeId.value,
          data: {
            title: challengeTitle.value,
            subject: challengeSubjects.value.join(", "),
            description: challengeDescription.value,
            types: challengeTypes.value,
            tags: challengeTags.value,
          },
        });
        await store.dispatch("challenge/GET_CHALLENGE", challengeId.value);
      } else {
        const challenge: Partial<Challenge> = {
          title: challengeTitle.value,
          subject: challengeSubjects.value.join(", "),
          description: challengeDescription.value,
          types: challengeTypes.value,
          tags: challengeTags.value,
        };
        store.commit("challenge/SET_CHALLENGE", challenge);
        store.dispatch("messages/ADD_INFO_MESSAGE", "CHALLENGE_UPDATED", {
          root: true,
        });
      }
      isExpanded.value = false;
    };

    watch(
      isLoading,
      () => {
        if (!isLoading.value) {
          // only update the existing fields if they're currently empty
          if (challenge.value?.title && challengeTitle.value === "") {
            challengeTitle.value = challenge.value.title;
          }
          if (
            challenge.value?.description &&
            challengeDescription.value === ""
          ) {
            challengeDescription.value = challenge.value.description;
          }
          if (challenge.value?.types && challengeTypes.value.length === 0) {
            challengeTypes.value = challenge.value.types;
          }
          if (
            challenge.value?.subject &&
            challengeSubjects.value.length === 0
          ) {
            challengeSubjects.value = challenge.value.subject.split(", ");
          }
          if (challenge.value?.tags && challengeTags.value.length === 0) {
            challengeTags.value = challenge.value.tags;
          }
        }
      },
      { immediate: true },
    );

    // Make the same API requests for users who have just logged in
    // (as they have logged in after the component has mounted)
    watch(
      () => auth0.isAuthenticated.value,
      async (newVal, oldVal) => {
        if (newVal && !oldVal) {
          requestUserFormData();
        } else {
          requestAllFormData();
        }
      },
      { immediate: true },
    );

    onBeforeRouteLeave(() => {
      if (!hasInitialDetails.value && !isDeletingChallenge.value) {
        // unsaved changes, so confirm before navigating away
        const answer = window.confirm(
          "Do you really want to leave? Your changes haven't been saved yet!",
        );
        if (!answer) return false;
      }
    });

    return {
      isAuthenticated: auth0.isAuthenticated,
      getRelativeDateString,
      isLoading,
      isExpanded,
      handleExpansionToggle,
      expandedContentEl,
      elementHeight,
      challengeIsActive,
      challengeTitle,
      challengeTitleEl,
      isLoadingChallengeTitle,
      challengeDescription,
      challengeDescriptionEl,
      challengeTypes,
      challengeSubjects,
      challengeTags,
      userSubjectOptions,
      allSubjectOptions,
      typesOptions,
      tagsOptions,
      createdAt,
      updatedAt,
      displayDeleteButton,
      showDeleteChallengeModal,
      submitIsDisabled,
      handleUpdate,
      isShown,
      showModal,
      closeModal,
      handleDeleteChallenge,
    };
  },
});
